import {useRef, useState, useEffect} from 'react'
import './invitation.scss'
import {configJsapi} from '../components'

let isStart = false
const audioSrc = 'https://joey-city.oss-cn-chengdu.aliyuncs.com/img/audio1.mp3'

export default function Invitation() {
  const audio = useRef()
  const [play, setPlay] = useState(false)

  function toggle() {
    isStart = true
    setPlay(!play)
    if (play) {
      setPlay(false)
      audio.current.pause()
    } else {
      setPlay(true)
      audio.current.play()
    }
  }

  function playAudio() {
    if (isStart) {
      return
    }
    isStart = true
    setPlay(true)
    audio.current.play()
  }

  useEffect(() => {
    document.title = '邀请函'
    const userAgent = window.navigator.userAgent
    if (userAgent.indexOf('Android') === -1) {
      if (typeof WeixinJSBridge == "undefined"){
        document.addEventListener('WeixinJSBridgeReady',function(){
          playAudio()
        },false)
      } else {
        WeixinJSBridge.invoke("getNetworkType", {}, function () {
          playAudio()
        })
      }
    }
    configJsapi(
      '四川名人馆项目封顶仪式暨劳动竞赛表彰大会',
      '邀请你',
      'https://index.chengdu91.top/invitation',
      'https://joey-index.oss-cn-chengdu.aliyuncs.com/assets/icon/invitation.jpg'
    )
  }, [])

  return (
    <div className="invitation">
      <div className={`audio-wrap ${play}`} onClick={toggle}>
        <div className="audio"></div>
      </div>
      <audio id="music" autoPlay ref={audio} loop src={audioSrc} />
    </div>
  )
}