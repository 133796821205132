import {useEffect} from 'react'
import {useFastReducer} from "../components/hooks"
import {getNum} from "../components"
import {authAjax} from "../components/ajax"
import './vote.scss'
import message from "../components/message"

export default function Vote({type}) {
  const title = '中国科学院成都分院职工摄影大赛'
  const [state, dispatch] = useFastReducer({
    tab: 0,
    tabList: ['风景榜', '人物榜'],
    list: [],
    input: '',
  })

  function handleInput(e) {
    dispatch({input: e.target.value})
  }

  function tabChange(tab) {
    dispatch({tab})
  }

  function search() {
    if (state.input === '') {
      message.warn('请输入作品编号')
    }
  }

  useEffect(() => {
    document.title = title
    authAjax.get(`/api/vote/getRankList`).then(res => {
      if (res.success) {
        dispatch({list: res.data || []})
      }
    })
  }, [])

  let viewList = [], peopleList = []
  let list = state.list
  if (list.length > 0) {
    viewList = list.filter(item => item.type === 1)
    peopleList = list.filter(item => item.type === 2)
  }

  return (
    <div className="vote-wrap">
      <div className="banner"></div>
      <div className="content-wrap">
        <div className="section">
          <div className="title-wrap">
            <div className="title">{title}</div>
            {type === 'inner' ? (
                <>
                  <div className="desc">1. 投票时间：5月15日9点至5月16日12点</div>
                  <div className="desc">2. 投票次数：每个评委可投40票，风景和人物各20票。（同一作品只能投一次票）</div>
                </>
            ) : (
                <>
                  <div className="desc">1. 投票时间：5月15日9点至5月17日24点</div>
                  <div className="desc">2. 投票次数：每人每天可投20票，风景和人物各10票。（同一作品当日只能投一次票）</div>
                </>
            )}
          </div>
        </div>
        <div className="section search-wrap">
          <input className="input" type="text" placeholder="输入编号查询" value={state.input} onChange={handleInput}/>
          <div className="btn" onClick={search}>搜索</div>
        </div>
        <div className="section tab-list">
          {state.tabList.map((item, i) => (
              <div key={i} className={`tab-item ${state.tab === i}`} onClick={() => tabChange(i)}>{item}</div>
          ))}
        </div>
        {state.tab === 0 && <Rank type={1} data={viewList} />}
        {state.tab === 1 && <Rank type={2} data={peopleList} />}
      </div>
    </div>
  )
}

function Rank({type, data}) {
  const list = data.slice().sort((a, b) => b.vote - a.vote)
  return (
    <div className="section rank-wrap">
      {list.map((item, i) => (
        <div key={item.id} className="rank-item">
          <div className="number">{i + 1}</div>
          <div className="avatar" style={{backgroundImage: `url(${item.index})`}}></div>
          <div className="grow">
            <div className="name">{item.name}</div>
            <div className="num blue">编号：{getNum(item.id)}号</div>
          </div>
          <div className="vote">
            <span className="blue">{item.vote}</span>
            <span>票</span>
          </div>
        </div>
      ))}
    </div>
  )
}
