import message from './message'

function get(url, headers) {
  return request('GET', url, null, headers)
}

function post(url, params, headers) {
  return request('POST', url, params, headers)
}

function request(method, url, params, headers) {
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    }
  }
  if (headers === 'auth') {
    const openId = localStorage.getItem('OPENID')
    if (openId) {
      options.headers['Open-Id'] = openId
    }
  }
  if (params) {
    options.body = JSON.stringify(params)
  }
  return fetch(url, options).then(res => {
    if (res.status === 200) {
      return res.json().then(json => {
        if (json.success) {
          return json
        } else {
          const msg = json.message
          message.warn(msg)
          return {success: false, message: msg}
        }
      })
    } else {
      const msg = 'Network error.'
      message.error(msg)
      return {success: false, message: msg}
    }
  })
}

const ajax = {get, post}

const authAjax = {
  get(url) {
    return get(url, 'auth')
  },
  post(url, params) {
    return post(url, params, 'auth')
  },
}

export {
  ajax,
  authAjax,
}