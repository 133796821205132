import {useParams} from 'react-router-dom'
import {useFastReducer} from '../components/hooks'
import {useEffect} from 'react'
import message from '../components/message'
import { authAjax } from '../components/ajax'
import './update.scss'

export default function Update() {
  const { id } = useParams()
  const [state, dispatch] = useFastReducer({
    id: id || '',
    type: '', // 类型：1 风景 2 人物
    index: '', // 封面
    src: '', // 作品地址
    name: '',
    desc: '',
    changeVote: !id, // 是否要更改投票数
    vote: '',
    innerVote: '',
  })

  function handleInput(e) {
    dispatch({[e.target.name]: e.target.value})
  }

  function handleCheck(e) {
    dispatch({changeVote: e.target.checked})
  }

  function submit() {
    if (!state.id) {
      message.warn('请填写编号')
      return
    }
    const params = {
      id: state.id,
      type: Number(state.type || 1),
      index: state.index,
      src: state.src,
      name: state.name,
      desc: state.desc,
    }
    if (state.changeVote) {
      params.vote = Number(state.vote || 0)
      params.innerVote = Number(state.innerVote || 0)
    }
    authAjax.post(`/api/vote/${id ? 'update' : 'add'}Photo`, params).then(res => {
      if (res.success) {
        message.success(res.message)
        if (!id) {
          dispatch({
            id: '',
            type: '',
            index: '',
            src: '',
            name: '',
            desc: '',
            changeVote: true,
            vote: '',
            innerVote: '',
          })
        }
      }
    })
  }

  useEffect(() => {
    if (id) {
      authAjax.get('/api/vote/getPhoto/inner/' + id).then(res => {
        if (res.success) {
          if (res.data) {
            dispatch(res.data)
          } else {
           message.warn("Can't find No." + id)
          }
        }
      })
    }
  }, [])

  return (
    <div className="vote-form">
      <div className='form-wrap'>
        <div className="label">No.</div>
        <input autoComplete="off" disabled={!!id} name="id" value={state.id} onChange={handleInput}/>
      </div>
      <div className='form-wrap'>
        <div className="label">type</div>
        <input autoComplete="off" className="flex" name="type" value={state.type} onChange={handleInput}/>
      </div>
      <div className='form-wrap'>
        <div className="label">index</div>
        <input autoComplete="off" className="flex" name="index" value={state.index} onChange={handleInput}/>
      </div>
      <div className='form-wrap'>
        <div className="label">src</div>
        <input autoComplete="off" className="flex" name="src" value={state.src} onChange={handleInput}/>
      </div>
      <div className='form-wrap'>
        <div className="label">name</div>
        <input autoComplete="off" className="flex" name="name" value={state.name} onChange={handleInput}/>
      </div>
      <div className='form-wrap'>
        <div className="label">desc</div>
        <input autoComplete="off" className="flex" name="desc" value={state.desc} onChange={handleInput}/>
      </div>
      <div className='form-wrap'>
        <div className="label">change vote?</div>
        <input type="checkbox" checked={state.changeVote} onChange={handleCheck}/>
      </div>
      <div className='form-wrap'>
        <div className="label">vote</div>
        <input autoComplete="off" disabled={!state.changeVote} type="number" name="vote" value={state.vote} onChange={handleInput}/>
      </div>
      <div className='form-wrap'>
        <div className="label">inner vote</div>
        <input autoComplete="off" disabled={!state.changeVote} type="number" name="innerVote" value={state.innerVote} onChange={handleInput}/>
      </div>
      <div className='submit-wrap'>
        <button onClick={submit}>save</button>
      </div>
    </div>
  )
}