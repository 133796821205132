import { createRoot } from 'react-dom/client'
import {createBrowserRouter, Outlet, useParams, RouterProvider, useLocation} from 'react-router-dom'
import Vote from './vote'
import VoteDetail from './vote/Detail'
import UpdateVote from './vote/Update'
import Invitation from './invitation'

import './app.scss'

function Layout() {
  return (
    <div className="layout-wrap">
      <Outlet />
    </div>
  )
}

function Auth() {
  const location = useLocation()
  const { openId } = useParams()

  // 不是微信环境跳转提示
  const ua = navigator.userAgent.toLocaleLowerCase()
  if (ua.indexOf('micromessenger') === -1) {
    return <Wechat />
  }

  if (openId) {
    // 授权回调，写openId
    localStorage.setItem('OPENID', openId)
    return <Outlet/>
  } else {
    // 已有openId缓存
    const cache = localStorage.getItem('OPENID')
    if (cache) {
      return <Outlet/>
    } else {
      // 没有openId，跳转授权链接
      const url = encodeURI('https://index.chengdu91.top/api/auth')
      window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxfab1c5590f1f3e3f&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=${location.pathname}#wechat_redirect`)
      return null
    }
  }
}

function Wechat() {
  return (
    <div className="wechat-tip">
      请使用微信APP打开此链接
    </div>
  )
}
function Empty() {
  return (
      <div className="wechat-tip">
        Oops! not found.
      </div>
  )
}

const router = createBrowserRouter([
  {path: '/', element: <Layout />, children: [
    {path: 'auth', element: <Auth />, children: [
      {path: 'vote', children: [{index: true, element: <Vote type="normal" />}, {path: ':openId', element: <Vote type="normal" />}]},
      {path: 'vote-detail/:id', element: <VoteDetail type="normal" />},
      {path: 'inner-vote', children: [{index: true, element: <Vote type="inner" />}, {path: ':openId', element: <Vote type="inner" />}]},
      {path: 'inner-vote-detail/:id', element: <VoteDetail type="inner" />},
    ]},
    {path: 'update-vote', children: [{index: true, element: <UpdateVote />}, {path: ':id', element: <UpdateVote />}]},
    {path: 'invitation', element: <Invitation />}
  ], errorElement: <Empty />}
])

createRoot(document.getElementById('app')).render(
  <RouterProvider router={router} />
)