import {ajax} from './ajax'
import message from './message'

function getNum(input) {
  if (input) {
    return input.split('-')[1]
  }
  return ''
}

function configJsapi(title, desc, link, imgUrl) {
  const ua = navigator.userAgent.toLocaleLowerCase()
  if (ua.indexOf('micromessenger') > -1) {
    ajax.post('/api/getConfig', {url: location.href}).then(res => {
      if (res.success) {
        wx.config({
          appId: res.data.appId, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature,// 必填，签名
          jsApiList: [
            'previewImage',
            'updateAppMessageShareData',
            'updateTimelineShareData',
          ] // 必填，需要使用的JS接口列表
        })
        wx.ready(function(){
          wx.updateAppMessageShareData({title, desc, link, imgUrl, success: function () {}})
          wx.updateTimelineShareData({title, link, imgUrl, success: function () {}})
        })
      }
    })
  }
}

export {
  getNum,
  configJsapi,
}