import {useEffect} from "react"
import {useParams, useNavigate} from 'react-router-dom'
import {useFastReducer} from "../components/hooks"
import {authAjax} from "../components/ajax"
import './detail.scss'
import {getNum, configJsapi} from "../components"
import message from "../components/message"

let loading = false
export default function Detail({type}) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [state, dispatch] = useFastReducer({
    data: {},
    vote: '',
  })

  function previewImg() {
    wx.previewImage({
      current: state.data.src, // 当前显示图片的http链接
      urls: [state.data.src] // 需要预览的图片http链接列表
    })
  }

  function vote() {
    if (loading) {return}
    loading = true
    setTimeout(() => {
      loading = false
    }, 3000)
    authAjax.post(`/api/vote/${type === 'inner' ? 'postInnerVote' : 'postVote'}`, {
      type: state.data.type,
      num: state.data.id,
    }).then(res => {
      if (res.success) {
        dispatch({
          vote: Number(state.vote) + 1,
          data: {...state.data, isVote: true}
        })
        message.success('投票成功')
      }
    })
  }

  function goBack() {
    navigate(-1)
  }

  function tip() {
    message.warn('已为该作品投票')
  }

  useEffect(() => {
    authAjax.get(`/api/vote/getPhoto/${type}/` + id).then(res => {
      if (res.success) {
        dispatch({
          data: res.data,
          vote: type === 'inner' ? res.data.innerVote : res.data.vote,
        })
      }
    })
    configJsapi(type)
  }, [])

  return (
    <div className="vote-detail">
      <div className="photo-wrap">
        <img className="img" src={state.data.src} alt="" onClick={previewImg}/>
        <div className="content">
          <div className="name">{state.data.name}</div>
          <div className="desc">{state.data.desc}</div>
        </div>
      </div>
      <div className="action-wrap">
        <div className="badge">编号：{getNum(state.data.id)}</div>
        <div className="left">票数：{state.vote}</div>
        {state.data.isVote ? (
            <div className="right yes" onClick={tip}>已投票</div>
        ): (
            <div className="right" onClick={vote}>立即投票</div>
        )}
      </div>
      <div className="back-btn" onClick={goBack}>返回</div>
    </div>
  )
}