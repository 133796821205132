import { createPortal } from 'react-dom'
import { createRoot } from 'react-dom/client'
import './message.scss'

const body = document.querySelector('body')
const toastEl = document.createElement('div')
toastEl.id = 'toast-root'
let flag = false // 同一时间只能有一个toast

function Toast({ type, msg }) {
  return createPortal(
    <div className="com-toast">
      <div className="toast-msg">
        <div className={`msg-icon ${type}`} />
        <div className="msg">{msg}</div>
      </div>
    </div>,
    toastEl
  )
}

function show(props) {
  if (flag) return
  flag = true
  body.appendChild(toastEl)
  const toastRoot = createRoot(toastEl)
  toastRoot.render(<Toast {...props} />)

  setTimeout(() => {
    flag = false
    toastRoot.unmount()
    body.removeChild(toastEl)
  }, 3000)
}

export default {
  success: msg => show({ type: 'success', msg }),
  warn: msg => show({ type: 'warn', msg }),
  error: msg => show({ type: 'error', msg })
}